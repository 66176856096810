import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import { IoMdNotifications } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { FiChevronsRight } from "react-icons/fi";
import navLogo from "../../assets/imgs/logo 1.svg";
import ButtonFill from "../ButtonFill/ButtonFill";
import { auth, signOut } from "../../firebase/firebaseConfig";

const NavBar = ({ title, setSearchTerm }) => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
      localStorage.clear();
    } catch (error) {
      console.error("Logout error: ", error);
    }
  };

  const options = { year: "numeric", month: "long", day: "numeric" };
  const currentDate = new Date().toLocaleDateString("en-PK", options);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    setSearchTerm(e.target.value);
  };

  return (
    <div className="navbar">
      <div className="d-flex align-items-center gap-4">
        <div
          className="sidebar-button-small-screen"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <img width="30" height="30" src={navLogo} alt="" />
        </div>
        <span className="nav-title">{title}</span>
        <div className="d-flex align-items-center">
          <span className="right-icon">
            <FiChevronsRight />
          </span>
          <span className="date">{currentDate}</span>
        </div>
      </div>
      <div className="navbar-actions">
        <span className="notification">
          <IoMdNotifications />
        </span>
        <div className="search-div">
          <input
            type="text"
            placeholder="Search here"
            className="search-input"
            value={searchInput}
            onChange={handleSearchChange}
          />
          <span className="search-icon">
            <CiSearch />
          </span>
        </div>
        <ButtonFill
          fillBtnWidth="100px"
          title="Logout"
          onclick={handleLogout}
        />
      </div>
    </div>
  );
};

export default NavBar;
