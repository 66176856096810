import React, { useState, useEffect } from "react";
import MainLayout from "../MainLayout/MainLayout";
import "./Settings.css";
import Security from "./Security/Security";
import { FaCamera } from "react-icons/fa";
import {
  auth,
  db,
  storage,
  doc,
  getDoc,
  updateDoc,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "../../firebase/firebaseConfig";
import Swal from "sweetalert2";

const Settings = () => {
  const [isIndex, setIsIndex] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [viewProfilePicture, setViewProfilePicture] = useState("");
  const [savePicture, setSavePicture] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        if (userData) {
          setName(userData.name || "");
          setEmail(userData.email || "");
          setPhoneNumber(userData.phoneNumber || "");
          setViewProfilePicture(userData.profileImageUrl || "");
        }
      }
    };
    fetchUserData();
  }, []);

  const updateProfileHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    const user = auth.currentUser;

    try {
      let profileImageUrl = viewProfilePicture;

      if (savePicture && savePicture[0]) {
        const storageRef = ref(storage, `users/${user.uid}/profileImage`);
        const uploadTask = uploadBytesResumable(storageRef, savePicture[0]);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            (error) => reject(error),
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                profileImageUrl = downloadURL;
                resolve();
              });
            }
          );
        });
      }

      await updateDoc(doc(db, "users", user.uid), {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        profileImageUrl: profileImageUrl,
      });

      const userData = {
        name: name,
        image: profileImageUrl,
      };
      localStorage.setItem("userName", JSON.stringify(userData));

      Swal.fire({
        icon: "success",
        title: "Profile Updated",
        text: "Your profile has been updated successfully!",
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error updating your profile. Please try again.",
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <MainLayout>
      <div className="main-content">
        <div className="">
          <span className="setting-heading">Settings</span>
        </div>
        <section className="main-card p-3">
          <div className="tabs d-flex mb-3">
            <button
              className={`tab ${isIndex === 0 && "active"}`}
              onClick={() => setIsIndex(0)}
            >
              Account
            </button>
            <button
              className={`tab ${isIndex === 1 && "active"}`}
              onClick={() => setIsIndex(1)}
            >
              Security
            </button>
          </div>
          {isIndex === 0 && (
            <div className="tab-content">
              <div className="mb-3 ps-3">
                <div className="imageStyles border mb-2">
                  <input
                    hidden
                    type="file"
                    id="profileImageUrl"
                    onChange={(e) => {
                      const base64Url = URL.createObjectURL(e.target.files[0]);
                      setViewProfilePicture(base64Url);
                      setSavePicture(e.target.files);
                    }}
                  />

                  {viewProfilePicture ? (
                    <div className="imageStyles" style={{ overflow: "hidden" }}>
                      <label
                        htmlFor="profileImageUrl"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={viewProfilePicture}
                          alt="Profile"
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                          }}
                        />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="h-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#0000008C",
                        borderRadius: "50%",
                      }}
                    >
                      <label
                        htmlFor="profileImageUrl"
                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <FaCamera fontSize="43" color="#CBD0DD" />
                      </label>
                    </div>
                  )}
                </div>
                <span style={{ color: "#191D23" }}>Profile Picture</span>
              </div>
              <form onSubmit={updateProfileHandler}>
                <div className="form-group mb-3">
                  <label htmlFor="full-name">Full Name</label>
                  <input
                    type="text"
                    id="full-name"
                    placeholder="Enter Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Tanzirrehman@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    placeholder="+1 234 5678 88888"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="update-profile"
                  disabled={loader}
                >
                  {loader ? "Updating..." : "Update Profile"}
                </button>
              </form>
            </div>
          )}
          {isIndex === 1 && <Security />}
        </section>
      </div>
    </MainLayout>
  );
};

export default Settings;
