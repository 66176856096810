import React, { useEffect, useState } from "react";
import "./SideBar.css";
import mainLogo from "../../assets/imgs/logo 1.svg";
import { RxDashboard } from "react-icons/rx";
import { LuCrown } from "react-icons/lu";
import { TbWallet } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { IoSettingsOutline } from "react-icons/io5";

const SideBar = ({ closeButton = false }) => {
  const location = useLocation();
  const [userName, setUserName] = useState(null);
  const [sideLoader, setSideLoader] = useState(
    !localStorage.getItem("userDataLoaded")
  );
  useEffect(() => {
    if (sideLoader) {
      const fetchUserData = () => {
        const storedUser = JSON.parse(localStorage.getItem("userName"));
        if (storedUser) {
          setUserName(storedUser);
        }
        setSideLoader(false);
        localStorage.setItem("userDataLoaded", true);
      };

      setTimeout(fetchUserData, 3000);
    } else {
      const storedUser = JSON.parse(localStorage.getItem("userName"));
      if (storedUser) {
        setUserName(storedUser);
      }
    }
  }, [sideLoader]);

  if (sideLoader) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // semi-transparent background
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000, // ensures it is above other content
          backdropFilter: "blur(1px)", // blurs the background
        }}
      >
        <RotatingLines
          visible={true}
          height="40"
          width="40"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
          strokeColor="#18432d"
        />
      </div>
    );
  }

  return (
    <div className="sidebar h-100 d-flex justify-content-between flex-column">
      <div>
        <div className="logos-container d-flex justify-content-center mb-5 gap-2 align-items-center">
          <img src={mainLogo} alt="HalalCheck Logo" className="logo-small" />
          <span className="sideHeading">HalalCheck</span>
          {closeButton && (
            <button
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              type="button"
            ></button>
          )}
        </div>
        <div>
          <ul className="menu">
            <Link to="/dashboard">
              <li
                className={`menu-item ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <RxDashboard />
                </span>{" "}
                <span>Overview</span>
              </li>
            </Link>
            <Link to="/subscriber">
              <li
                className={`menu-item ${
                  location.pathname === "/subscriber" ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <LuCrown />
                </span>{" "}
                <span>Subscriber</span>
              </li>
            </Link>
            <Link to="/sales">
              <li
                className={`menu-item ${
                  location.pathname === "/sales" ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <TbWallet />
                </span>{" "}
                <span>Sales</span>
              </li>
            </Link>
            <Link to="/settings">
              <li
                className={`menu-item ${
                  location.pathname === "/settings" ? "active" : ""
                }`}
              >
                <span className="menu-icon">
                  <IoSettingsOutline />
                </span>{" "}
                <span>Settings</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div>
        {/* <Link to="/settings"> */}
        <div className="profile">
          <div>
            <img src={userName?.image || ""} alt="" className="profile-img" />
          </div>
          <div>
            <span className="profile-name">{userName?.name}</span>
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default SideBar;
