import React from "react";

const ButtonFill = ({ onclick, className, fillBtnWidth, title, disabled }) => {
  return (
    <div className={className} style={{ width: fillBtnWidth }}>
      <button
        disabled={disabled}
        className="fillBtn w-100"
        type="submit"
        onClick={onclick}
      >
        {title}
      </button>
    </div>
  );
};

export default ButtonFill;
