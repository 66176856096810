import React, { useState } from "react";
import {
  auth,
  signInWithEmailAndPassword,
  updatePassword,
  doc,
  updateDoc,
  db,
} from "../../../firebase/firebaseConfig";
import Swal from "sweetalert2";

const Security = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const updateProfileHandler1 = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoader(true);

    try {
      const user = auth.currentUser;
      const email = user.email;
      const userUID = user.uid;

      await signInWithEmailAndPassword(auth, email, oldPassword);

      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        return;
      }

      await updatePassword(user, newPassword);

      const userRef = doc(db, "users", userUID);
      await updateDoc(userRef, {
        password: newPassword,
      });

      Swal.fire({
        icon: "success",
        title: "Password Updated",
        text: "Your password has been updated successfully!",
      });

      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setLoader(false);
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        setErrorMessage("Incorrect old password");
        setLoader(false);
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage("User not found");
        setLoader(false);
      } else if (error.code === "auth/too-many-requests") {
        setErrorMessage("Too many failed attempts. Please try again later.");
        setLoader(false);
      } else {
        setErrorMessage("Something went wrong, please try again.");
        setLoader(false);
      }
    }
  };

  return (
    <div className="pt-4 pb-5">
      <form onSubmit={updateProfileHandler1}>
        <div className="form-group mb-3 d-flex flex-column">
          <label htmlFor="oldPassword" className="mb-2">
            Old Password
          </label>
          <input
            type="password"
            id="oldPassword"
            placeholder="Enter your old password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          {errorMessage === "Incorrect old password" && (
            <span style={{ color: "red" }}>Incorrect old password</span>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="newPassword" className="mb-2">
            New Password
          </label>
          <input
            type="password"
            id="newPassword"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 d-flex flex-column">
          <label htmlFor="confirmPassword" className="mb-2">
            Confirm New Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errorMessage === "Passwords do not match" && (
            <span style={{ color: "red" }}>Passwords do not match</span>
          )}
        </div>
        <button type="submit" className="update-profile mt-3" disabled={loader}>
          {loader ? "Updating..." : "Update Profile"}
        </button>
      </form>
    </div>
  );
};

export default Security;
