import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import ForgotPage from "./pages/ForgotPage";
import DashboardPage from "./pages/DashboardPage";
import SettingsPage from "./pages/SettingsPage";
import SalesPage from "./pages/SalesPage";
import SubscriberPage from "./pages/SubscriberPage";
import { auth, onAuthStateChanged } from "./firebase/firebaseConfig";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unSubscribe();
  }, [auth]);
  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          backdropFilter: "blur(1px)",
        }}
      >
        <RotatingLines
          visible={true}
          height="40"
          width="40"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
          strokeColor="#18432d"
        />
      </div>
    );
  }
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard" /> : <LoginPage />}
        ></Route>
        <Route
          path="/forgot-password"
          element={user ? <Navigate to="/dashboard" /> : <ForgotPage />}
        ></Route>
        <Route
          path="/dashboard"
          element={user ? <DashboardPage /> : <Navigate to="/" />}
        ></Route>
        <Route
          path="/subscriber"
          element={user ? <SubscriberPage /> : <Navigate to="/" />}
        ></Route>
        <Route
          path="/sales"
          element={user ? <SalesPage /> : <Navigate to="/" />}
        ></Route>
        <Route
          path="/settings"
          element={user ? <SettingsPage /> : <Navigate to="/" />}
        ></Route>
      </Routes>
    </>
  );
}

export default App;
