import React, { useState } from "react";
import "./Table.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RotatingLines } from "react-loader-spinner";

const renderDashboardTable = (
  data,
  handleClick,
  toggleDropdown,
  dropdownId
) => {
  return (
    <>
      <td className="">{data.userName}</td>{" "}
      <td className="">{data.shopName}</td>{" "}
      <td className="">{data.shopAddress}</td>{" "}
      <td className="">{data.shopCity}</td>{" "}
      <td className="">{data.shopCountry}</td>{" "}
      <td className="">{data.shopCategories}</td>{" "}
      <td className="text-center">{data.status}</td>
      <td className="text-center">
        <div style={{ position: "relative" }}>
          <HiOutlineDotsVertical
            cursor="pointer"
            onClick={() => toggleDropdown(dropdownId)}
          />
          {data.isDropdownOpen && (
            <ul
              className="dropdown-menu"
              style={{ position: "absolute", top: "20px", right: "4px" }}
            >
              <li onClick={() => handleClick("view", data)}>View</li>
              <li onClick={() => handleClick("edit", data)}>Edit</li>
              <li onClick={() => handleClick("delete", data)}>Delete</li>
            </ul>
          )}
        </div>
      </td>
    </>
  );
};

const renderSubscriberTable = (
  data,
  handleClick,
  toggleDropdown,
  dropdownId
) => {
  return (
    <>
      <td className="">{data.userName}</td>{" "}
      <td className="">{data.shopName}</td>{" "}
      <td className="">{data.shopAddress}</td>{" "}
      <td className="">{data.shopCity}</td>{" "}
      <td className="">{data.shopCountry}</td>{" "}
      <td className="">{data.shopCategories}</td>{" "}
      <td className="text-center">{data.status}</td>
      <td className="text-center">
        <div style={{ position: "relative" }}>
          <HiOutlineDotsVertical
            cursor="pointer"
            onClick={() => toggleDropdown(dropdownId)}
          />
          {data.isDropdownOpen && (
            <ul
              className="dropdown-menu"
              style={{ position: "absolute", top: "20px", right: "4px" }}
            >
              <li onClick={() => handleClick("view", data)}>View</li>
              <li onClick={() => handleClick("edit", data)}>Edit</li>
              <li onClick={() => handleClick("delete", data)}>Delete</li>
            </ul>
          )}
        </div>
      </td>
    </>
  );
};

const renderSalesTable = (data, handleClick) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const subEndDate = new Date(data.subEndDate);

  const currentDate = new Date();

  if (subEndDate > currentDate) {
    const day = String(subEndDate.getDate()).padStart(2, "0");
    const month = monthNames[subEndDate.getMonth()];
    const year = subEndDate.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return (
      <>
        <td className="text-center">{formattedDate}</td>{" "}
        <td className="text-center">{data.platform}</td>{" "}
        <td className="text-center">{data.id}</td>{" "}
        <td className="text-center">{data.price}</td>{" "}
      </>
    );
  }
};

const Table = ({
  headers,
  tableData,
  dashboardTable,
  subscriberTable,
  handleClick,
  salesTable,
  loader,
}) => {
  const [dropdownOpenId, setDropdownOpenId] = useState(null);

  const toggleDropdown = (id) => {
    setDropdownOpenId(dropdownOpenId === id ? null : id);
  };
  return (
    <div className="table-content">
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loader ? (
            <tr>
              <td colSpan={headers.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    strokeColor="#18432d"
                  />
                </div>
              </td>
            </tr>
          ) : tableData.length > 0 ? (
            tableData.map((tableContent, idx) => (
              <tr key={idx}>
                {dashboardTable &&
                  renderDashboardTable(
                    { ...tableContent, isDropdownOpen: dropdownOpenId === idx },
                    handleClick,
                    toggleDropdown,
                    idx
                  )}
                {subscriberTable &&
                  renderSubscriberTable(
                    { ...tableContent, isDropdownOpen: dropdownOpenId === idx },
                    handleClick,
                    toggleDropdown,
                    idx
                  )}
                {salesTable &&
                  renderSalesTable(tableContent, loader, handleClick)}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length} style={{ textAlign: "center" }}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
