import React, { useEffect, useState } from "react";
import "./AddShopForm.css";
import { FaCamera } from "react-icons/fa";
import ButtonFill from "../../ButtonFill/ButtonFill";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { RotatingLines } from "react-loader-spinner";
import {
  ref,
  storage,
  uploadBytesResumable,
  getDownloadURL,
  auth,
  createUserWithEmailAndPassword,
  collection,
  doc,
  db,
  getDocs,
  setDoc,
  query,
  arrayUnion,
  updateDoc,
  where,
} from "../../../firebase/firebaseConfig";
import { customAlphabet } from "nanoid";
import Modal from "react-modal"; // Import Modal package if not installed already
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";

const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

Modal.setAppElement("#root");

const AddShopForm = ({
  shopData,
  isViewMode,
  isEditMode,
  setIndex,
  setIsIndex,
}) => {

  // alert message show state
  const [success, setSuccess] = useState(false);
  const [success2, setSuccess2] = useState(false);

  // Edit states
  const [editViewCompanyLogo, setEditViewCompanyLogo] = useState("");
  const [editSaveLogo, setEditSaveLogo] = useState("");
  const [editViewCompanyPics, setEditViewCompanyPics] = useState("");
  const [editSaveCompanyPics, setEditSaveCompanyPics] = useState("");
  const [editViewCertificate, setEditViewCertificate] = useState("");
  const [editSaveCertificate, setEditSaveCertificate] = useState("");

  // show image screen states
  const [viewProfilePicture, setViewProfilePicture] = useState("");
  const [viewCompanyLogo, setViewCompanyLogo] = useState("");
  const [viewCompanyPics, setViewCompanyPics] = useState("");

  // farward image states:
  const [savePicture, setSavePicture] = useState("");
  const [saveLogo, setSaveLogo] = useState("");

  const [saveCertificate, setSaveCertificate] = useState("");

  const [saveCompanyPics, setSaveCompanyPics] = useState("");
  // ---------------------------------------
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [is12HourFormat, setIs12HourFormat] = useState(false); // default is false (24-hour format)
  const [allDay, setAllDay] = useState(false);
  const [shopOpeningHours, setShopOpeningHours] = useState(
    dayNames.map((day, ind) => ({
      id: ind + 1,
      day,
      isOpen: false,
      openingTime: "10:00",
      closingTime: "18:00",
    }))
  );
  const [addShop, setAddShop] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    shopName: "",
    shopAddress: "",
    shopCity: "",
    shopCountry: "",
    shopDistrict: "",
    shopPostalCode: "",
    companyName: "",
    description: "",
    openingDaysTime: "",
    shopCategory: "",
  });
  const [editShop, setEditShop] = useState({
    editShopName: "",
    editShopAddress: "",
    editShopCity: "",
    editShopCountry: "",
    editShopDistrict: "",
    editShopPostalCode: "",
    editCompanyName: "",
    editDescription: "",
    editOpeningDaysTime: "",
    editShopCategory: "",
  });

  const [editIs12HourFormat, setEditIs12HourFormat] = useState(false); // default is false (24-hour format)
  const [editAllDay, setEditAllDay] = useState(false);

  useEffect(() => {
    if (success) {
      Swal.fire({
        title: "Shop Created Successfully!",
        icon: "success",
        timer: 3000,
      });
    }
    if (success2) {
      Swal.fire({
        title: "Shop Updated Successfully!",
        icon: "success",
        timer: 3000,
      }).then(() => {
        setIndex(0); // Reset index to 0 after the success alert
        setIsIndex(0); // Reset index to 0 after the success alert
      });
    }
  }, [success, success2]);

  useEffect(() => {
    if (shopData) {
      setAddShop(shopData);
    }
  }, [shopData]);

  useEffect(() => {
    if (isEditMode && shopData) {
      setEditShop({
        editShopName: shopData?.name,
        editShopAddress: shopData?.address,
        editShopCity: shopData?.city,
        editShopCountry: shopData?.country,
        editShopDistrict: shopData?.district,
        editShopPostalCode: shopData?.postalCode,
        editCompanyName: shopData?.companyName,
        editDescription: shopData?.description,
        editShopCategory: shopData?.category,
      });
      setEditViewCompanyPics(shopData?.shopPicturesUrls);
      setEditViewCompanyLogo(shopData?.logo);
      setEditViewCertificate(shopData?.certificate);
      setEditIs12HourFormat(shopData?.shopOpeningHours.is12HourFormat || false);
      setShopOpeningHours(
        shopData?.shopOpeningHours.timing.map((day) => ({
          id: day.id,
          day: day.day,
          isOpen: day.isOpen,
          openingTime: day.openingTime || "10:00",
          closingTime: day.closingTime || "18:00",
        }))
      );
    }
  }, [shopData, isEditMode]);

  const toggleDaySelection = (id) => {
    setShopOpeningHours((prevState) =>
      prevState.map((day) =>
        day.id === id ? { ...day, isOpen: !day.isOpen } : day
      )
    );
  };

  const toggleAllDay = () => {
    setAllDay(!allDay);
    const updatedHours = shopOpeningHours.map((day) => ({
      ...day,
      isOpen: !allDay,
    }));
    setShopOpeningHours(updatedHours);
  };

  const handleTimeChange = (id, field, value) => {
    setShopOpeningHours((prevState) =>
      prevState.map((day) => (day.id === id ? { ...day, [field]: value } : day))
    );
  };

  const handleSave = () => {
    setModalIsOpen(false);
    // Save shopOpeningHours to Firebase or use it as needed
    // Log the current shop opening hours for debugging
    // console.log("Saved shopOpeningHours:", shopOpeningHours);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddShop({
      ...addShop,
      [name]: value,
    });
  };

  // Handler function to create a new addShop data
  const createNewShopHandler = async (e) => {
    e.preventDefault();
    if (addShop.password !== addShop.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      setLoading(true); // Start the loader
      const response = await createUserWithEmailAndPassword(
        auth,
        addShop.email,
        addShop.password
      );
      const userId = response.user.uid;
      const alphabet =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const customNanoid = customAlphabet(alphabet, 20);
      const uniqueId = customNanoid();

      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("email", "==", addShop.email),
        where("name", "==", addShop.fullName)
      );
      const querySnapshot = await getDocs(q);

      const uploadFiles = async (files, path) => {
        if (!files || files.length === 0) return [];

        const promises = [];
        for (const file of files) {
          const imageRef = ref(storage, `${path}/${file.name}`); // Path based on the structure you want
          const uploadTask = uploadBytesResumable(imageRef, file);

          const promise = new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
              },
              (error) => {
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL);
                });
              }
            );
          });

          promises.push(promise);
        }

        return Promise.all(promises); // Returns array of download URLs
      };

      // Upload profile picture to users/userId/profileImage
      const profileImagePath = `users/${userId}/profileImage`;
      const resProfileImage = await uploadFiles(
        [savePicture[0]],
        profileImagePath
      );

      // Upload logo and certificate to shop/userId/shopDocuments
      const shopDocumentsPath = `shop/${userId}/shopDocuments`;
      const resShopDocuments = await uploadFiles(
        [saveLogo[0], saveCertificate[0]],
        shopDocumentsPath
      );

      // Upload company pictures to shop/userId/shopPictures
      const shopPicturesPath = `shop/${userId}/shopPictures`;
      const resShopPictures = await uploadFiles(
        saveCompanyPics,
        shopPicturesPath
      );

      let userDocId = null;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          userDocId = doc.id;
        });
        await updateDoc(doc(db, "users", userDocId), {
          shopIds: arrayUnion(uniqueId),
        });
      } else {
        await setDoc(doc(db, "users", userId), {
          email: addShop.email,
          name: addShop.fullName,
          isShopOwner: true,
          password: addShop.password,
          phoneNumber: addShop.phoneNumber,
          platform: "web",
          profileImageUrl: resProfileImage[0],
          uid: userId,
          shopId: [uniqueId],
        });
      }

      await setDoc(doc(db, "shops", uniqueId), {
        address: addShop.shopAddress,
        category: addShop.shopCategory,
        certificate: resShopDocuments[1],
        city: addShop.shopCity,
        companyName: addShop.companyName,
        country: addShop.shopCountry,
        description: addShop.description,
        district: addShop.shopDistrict,
        logo: resShopDocuments[0],
        name: addShop.shopName,
        id: uniqueId,
        postalCode: addShop.shopPostalCode,
        shopOpeningHours: {
          is12HourFormat: is12HourFormat, // This will dynamically pass true or false
          timing: shopOpeningHours, // The rest of the timing data
        },
        shopPicturesUrls: resShopPictures,
        shopOwnerId: userId,
      });
      console.log("all apis called");

      e.target.reset();
      setViewCompanyLogo("");
      setViewProfilePicture("");
      setViewCompanyPics("");
      // Reset the new states
      setIs12HourFormat(false); // Reset to default false
      setAllDay(false); // Reset to default false
      setShopOpeningHours(
        dayNames.map((day, ind) => ({
          id: ind + 1,
          day,
          isOpen: false,
          openingTime: "10:00", // Reset to default time
          closingTime: "18:00", // Reset to default time
        }))
      );
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.error("Error creating shop:", error);
      setSuccess(false); // Reset success state if there is an error
    } finally {
      setLoading(false); // Stop the loader here after all operations are completed
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditShop({
      ...editShop,
      [name]: value,
    });
  };

  // Function to update shop data in edit mode
  const updateShopHandler = async () => {
    try {
      setLoading(true); // Start the loader
      const shopDocRef = doc(db, "shops", shopData?.id); // Use editShop.id for shop reference

      const uploadFiles = async (files, path) => {
        if (!files || files.length === 0) return [];

        const promises = [];
        for (const file of files) {
          if (!file || !file.name) {
            continue; // Skip any invalid files
          }
          const imageRef = ref(storage, `${path}/${file.name}`); // Path based on the structure you want
          const uploadTask = uploadBytesResumable(imageRef, file);

          const promise = new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
              },
              (error) => {
                reject(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  resolve(downloadURL);
                });
              }
            );
          });

          promises.push(promise);
        }

        return Promise.all(promises); // Returns array of download URLs
      };

      // Upload files logic for logo and certificate
      const shopDocumentsPath = `shop/${shopData?.uid}/shopDocuments`;
      let resShopDocuments = [null, null]; // Default to null if no documents
      if (editSaveLogo.length > 0 || editSaveCertificate.length > 0) {
        resShopDocuments = await uploadFiles(
          [
            editSaveLogo.length > 0 ? editSaveLogo[0] : null, // Upload logo if present
            editSaveCertificate.length > 0 ? editSaveCertificate[0] : null, // Upload certificate if present
          ],
          shopDocumentsPath
        );
      }
      // Log the certificate URL for verification
      if (resShopDocuments[1]) {
        console.log("Certificate URL:", resShopDocuments[1]); // New certificate URL
      }

      // Upload company pictures to shop/userId/shopPictures
      const shopPicturesPath = `shop/${shopData?.uid}/shopPictures`;
      // Upload new company pictures if any are selected
      let resShopPictures = [];
      if (editSaveCompanyPics.length > 0) {
        resShopPictures = await uploadFiles(
          editSaveCompanyPics,
          shopPicturesPath
        );
      }

      // Merge the new images with the existing ones
      const updatedShopPictures =
        resShopPictures.length > 0
          ? [...resShopPictures] // Use only new images if uploaded
          : shopData?.shopPicturesUrls || []; // Keep the old ones if no new ones are uploaded;

      // Update the shop document
      await updateDoc(shopDocRef, {
        address: editShop?.editShopAddress,
        category: editShop?.editShopCategory,
        certificate: resShopDocuments[1] || shopData?.certificate,
        city: editShop?.editShopCity,
        companyName: editShop?.editCompanyName,
        country: editShop?.editShopCountry,
        description: editShop?.editDescription,
        district: editShop?.editShopDistrict,
        logo: resShopDocuments[0] || shopData?.logo,
        name: editShop?.editShopName,
        postalCode: editShop?.editShopPostalCode,
        shopOpeningHours: {
          is12HourFormat: is12HourFormat, // This will dynamically pass true or false
          timing: shopOpeningHours, // The updated timing data
        },
        shopPicturesUrls: updatedShopPictures,
      });

      setLoading(false);
      setSuccess2(true); // Indicate success
      console.log("Shop and user updated successfully!");
    } catch (error) {
      console.error("Error updating shop:", error);
      setSuccess(false); // Indicate failure
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ensure the modal's data is saved
    if (modalIsOpen) {
      handleSave(); // This will close the modal and update the state
    }
    if (isEditMode) {
      // Call Update shop handle
      updateShopHandler();
    } else {
      // Call create new shop handle
      createNewShopHandler();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const categories = [
    "Cars",
    "Beauty and Spas",
    "Electronics",
    "Fitness",
    "Leisure and Kids",
    "Jeweler",
    "Art and Entertainment",
    "Restaurant",
    "Shop",
    "Local Services",
    "Medicine",
    "Travel",
    "Pets",
    "Furniture",
  ];

  return (
    <div className="row bg-white m-0 pt-3" style={{ borderRadius: "8px" }}>
      {isEditMode ? (
        <form className="d-flex" onSubmit={handleSubmit}>
          <div className="col-md-5 px-4">
            <div>
              <div className="mb-3 ps-3">
                <div className="imageStyles border mb-2">
                  <input
                    hidden
                    type="file"
                    id="profilePic"
                    disabled={isEditMode}
                    //required
                    onChange={(e) => {
                      const base64Url = URL.createObjectURL(e.target.files[0]);
                      setViewProfilePicture(base64Url);
                      setSavePicture(e.target.files);
                    }}
                  />
                  {viewProfilePicture || shopData?.profileImageUrl ? (
                    <div className="imageStyles" style={{ overflow: "hidden" }}>
                      <label htmlFor="profilePic" style={{ cursor: "pointer" }}>
                        <img
                          src={viewProfilePicture || shopData?.profileImageUrl}
                          alt="image"
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                          }}
                        />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="h-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#0000008C",
                        borderRadius: "50%",
                      }}
                    >
                      <label
                        htmlFor="profilePic"
                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <FaCamera fontSize="43" color="#CBD0DD" />
                      </label>
                    </div>
                  )}
                </div>

                <span style={{ color: "#191D23" }}>Profile Picture</span>
              </div>
              <div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Full Name"
                    value={shopData?.userName}
                    disabled={isEditMode} // Disable in view mode
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    value={shopData?.email}
                    disabled={isEditMode} // Disable in view mode
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="password">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={shopData?.password}
                    disabled={isEditMode} // Disable in view mode
                  />
                  <span
                    className="toggle-profilePassword"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                  </span>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={shopData?.password}
                    disabled={isEditMode} // Disable in view mode
                  />
                  <span
                    className="toggle-profilePassword"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <IoEyeOutline />
                    ) : (
                      <IoEyeOffOutline />
                    )}
                  </span>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    value={shopData?.phoneNumber}
                    disabled={isEditMode} // Disable in view mode
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopName">
                    Shop Name
                  </label>
                  <input
                    type="text"
                    id="shopName"
                    placeholder="Shop Name"
                    value={editShop?.editShopName || ""}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopAddress">
                    Shop Address
                  </label>
                  <input
                    type="text"
                    id="shopAddress"
                    placeholder="Shop Address"
                    value={editShop?.editShopAddress}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopAddress: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopCity">
                    Shop City
                  </label>
                  <input
                    type="text"
                    id="shopCity"
                    placeholder="Shop City"
                    value={editShop?.editShopCity}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopCity: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="country">
                    Shop Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    placeholder="Shop Country"
                    value={editShop?.editShopCountry}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopCountry: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="district">
                    Shop District
                  </label>
                  <input
                    type="text"
                    id="district"
                    placeholder="Shop District"
                    value={editShop?.editShopDistrict}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopDistrict: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="postalCode">
                    Shop Postal Code
                  </label>
                  <input
                    type="number"
                    id="postalCode"
                    placeholder="Shop Postal Code"
                    value={editShop?.editShopPostalCode}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopPostalCode: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 px-4">
            <div>
              <div className="mb-3 ps-3">
                <div className="imageStyles border mb-2">
                  <input
                    hidden
                    type="file"
                    id="companyLogo"
                    name="companyLogo"
                    onChange={(e) => {
                      // Check if file is selected
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        const base64Url2 = URL.createObjectURL(file); // Preview the selected image immediately
                        setEditViewCompanyLogo(base64Url2); // Set the preview
                        setEditSaveLogo([file]); // Save the selected file for uploading
                      }
                    }}
                  />

                  {editViewCompanyLogo || shopData?.logo ? (
                    <div className="imageStyles" style={{ overflow: "hidden" }}>
                      <label
                        htmlFor="companyLogo"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={editViewCompanyLogo || shopData?.logo} // Display the preview or fallback to shop logo
                          alt="logo"
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                          }}
                        />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="h-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#0000008C",
                        borderRadius: "50%",
                      }}
                    >
                      <label
                        htmlFor="companyLogo"
                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <FaCamera fontSize="43" color="#CBD0DD" />
                      </label>
                    </div>
                  )}
                </div>

                <span style={{ color: "#191D23" }}>Company Logo</span>
              </div>
              <div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Company Name"
                    value={editShop?.editCompanyName}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editCompanyName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={editShop?.editDescription}
                    rows={4}
                    cols={40}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editDescription: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="day&time">
                    Opening Days & Time
                  </label>
                  <button
                    type="button"
                    onClick={() => setModalIsOpen(true)}
                  >
                    Select Opening Days & Time
                  </button>
                  {/* Modal for selecting opening hours */}
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className="modal-main-div col-lg-4 col-12 offset-lg-4 mt-1"
                  >
                    <div>
                      <div className="modal-header">
                        <h3>Shop Timing</h3>
                        <FaTimes
                          onClick={() => setModalIsOpen(false)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      <div>
                        <span>Select Shop Opening Days & Time</span>
                      </div>

                      <div className="modal-body">
                        <label>
                          <input
                            type="checkbox"
                            checked={allDay}
                            onChange={toggleAllDay}
                          />
                          All Day
                        </label>
                        <label style={{ float: "right" }}>
                          24 Hour format
                          <input
                            type="checkbox"
                            value={editIs12HourFormat}
                            checked={!is12HourFormat} // Inverse logic, checked for 24-hour format
                            onChange={() => setIs12HourFormat(!is12HourFormat)} // Toggle state
                          />
                        </label>

                        {shopOpeningHours.map((day) => (
                          <div key={day.id} className="day-row mt-2 mb-2">
                            <label>
                              <input
                                type="checkbox"
                                checked={day.isOpen}
                                onChange={() => toggleDaySelection(day.id)}
                              />
                              {day.day}
                            </label>
                            <div className="time-inputs">
                              <input
                                type="time"
                                value={day.openingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day.id,
                                    "openingTime",
                                    e.target.value
                                  )
                                }
                                disabled={!day.isOpen}
                              />
                              <input
                                type="time"
                                value={day.closingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day.id,
                                    "closingTime",
                                    e.target.value
                                  )
                                }
                                disabled={!day.isOpen}
                              />
                            </div>
                          </div>
                        ))}

                        <button onClick={handleSave}>Save</button>
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="category">
                    Shop Category
                  </label>
                  <select
                    name="category"
                    value={editShop?.editShopCategory}
                    required
                    onChange={(e) =>
                      setEditShop({
                        ...editShop,
                        editShopCategory: e.target.value,
                      })
                    }
                  >
                    {!isViewMode && (
                      <option value="" hidden>
                        Shop Category
                      </option>
                    )}
                    {categories.map((category, idx) => (
                      <option key={idx} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="certificate">
                    Halal Certificate
                  </label>
                  <input
                    type="file"
                    id="certificate"
                    onChange={(e) => {
                      // Check if file is selected
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        const base64Url2 = URL.createObjectURL(file); // Preview the selected image immediately
                        setEditViewCertificate(base64Url2); // Set the preview
                        setEditSaveCertificate([file]); // Save the selected file for uploading
                      }
                    }}
                  />
                  {/* Show the previous certificate link or name (optional) */}
                  {shopData?.certificate && (
                    <div className="mt-2">
                      <p className="w-100" style={{ height: "2rem" }}>
                        Previous Certificate:{" "}
                        <a
                          href={editViewCertificate || shopData.certificate}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Certificate
                        </a>
                      </p>
                    </div>
                  )}
                </div>

                <div className="input-one">
                  <label className="mb-1" htmlFor="companyPictures">
                    Company Pictures
                  </label>
                  <input
                    type="file"
                    id="companyPictures"
                    maxLength="8"
                    multiple
                    // Inside the input change handler
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files) {
                        const base64Urls = Array.from(files).map((file) =>
                          URL.createObjectURL(file)
                        );
                        setEditViewCompanyPics(base64Urls); // Display only new images
                        setEditSaveCompanyPics(files); // Save only the new files for upload
                      }
                    }}
                  />
                  <div className="selected-images d-flex gap-2 flex-wrap mt-2">
                    {Array.isArray(editViewCompanyPics) &&
                    editViewCompanyPics.length > 0 ? (
                      editViewCompanyPics.map((url, idx) => (
                        <img
                          key={idx}
                          src={url}
                          alt={`image-${idx}`}
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                          }}
                        />
                      ))
                    ) : Array.isArray(shopData?.shopPicturesUrls) &&
                      shopData?.shopPicturesUrls.length > 0 ? (
                      shopData.shopPicturesUrls.map((url, idx) => (
                        <img
                          key={idx}
                          src={url}
                          alt={`image-${idx}`}
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                          }}
                        />
                      ))
                    ) : (
                      <p className="text-center pt-4">No images available</p> // Optional fallback message
                    )}
                  </div>
                </div>
              </div>

              <ButtonFill
                disabled={isViewMode}
                className={viewCompanyPics ? "mt-1" : "mt-5"}
                title={isEditMode ? "Update Shop" : "Save Shop"}
              />

              {loading && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // semi-transparent background
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000, // ensures it is above other content
                    backdropFilter: "blur(1px)", // blurs the background
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    strokeColor="#18432d"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </form>
      ) : (
        <form className="d-flex" onSubmit={handleSubmit}>
          <div className="col-md-5 px-4">
            <div>
              <div className="mb-3 ps-3">
                <div className="imageStyles border mb-2">
                  <input
                    hidden
                    type="file"
                    id="profilePic"
                    disabled={isViewMode}
                    onChange={(e) => {
                      const base64Url = URL.createObjectURL(e.target.files[0]);
                      setViewProfilePicture(base64Url);
                      setSavePicture(e.target.files);
                    }}
                  />
                  {viewProfilePicture || shopData?.profileImageUrl ? (
                    <div className="imageStyles" style={{ overflow: "hidden" }}>
                      <label htmlFor="profilePic" style={{ cursor: "pointer" }}>
                        <img
                          src={viewProfilePicture || shopData?.profileImageUrl}
                          alt="image"
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                          }}
                        />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="h-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#0000008C",
                        borderRadius: "50%",
                      }}
                    >
                      <label
                        htmlFor="profilePic"
                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <FaCamera fontSize="43" color="#CBD0DD" />
                      </label>
                    </div>
                  )}
                </div>

                <span style={{ color: "#191D23" }}>Profile Picture</span>
              </div>
              <div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="fullName">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Full Name"
                    value={shopData?.userName || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    value={shopData?.email || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="password">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={shopData?.password || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                  <span
                    className="toggle-profilePassword"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                  </span>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={shopData?.password || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                  <span
                    className="toggle-profilePassword"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <IoEyeOutline />
                    ) : (
                      <IoEyeOffOutline />
                    )}
                  </span>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    placeholder="Phone Number"
                    value={shopData?.phoneNumber || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopName">
                    Shop Name
                  </label>
                  <input
                    type="text"
                    id="shopName"
                    placeholder="Shop Name"
                    value={shopData?.name || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopAddress">
                    Shop Address
                  </label>
                  <input
                    type="text"
                    id="shopAddress"
                    placeholder="Shop Address"
                    value={shopData?.address || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="shopCity">
                    Shop City
                  </label>
                  <input
                    type="text"
                    id="shopCity"
                    placeholder="Shop City"
                    value={shopData?.city || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="country">
                    Shop Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    placeholder="Shop Country"
                    value={shopData?.country || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="district">
                    Shop District
                  </label>
                  <input
                    type="text"
                    id="district"
                    placeholder="Shop District"
                    value={shopData?.district || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="postalCode">
                    Shop Postal Code
                  </label>
                  <input
                    type="number"
                    id="postalCode"
                    placeholder="Shop Postal Code"
                    value={shopData?.postalCode || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 px-4">
            <div>
              <div className="mb-3 ps-3">
                <div className="imageStyles border mb-2">
                  <input
                    hidden
                    type="file"
                    id="companyLogo"
                    disabled={isViewMode}
                    onChange={(e) => {
                      const base64Url2 = URL.createObjectURL(e.target.files[0]);
                      setViewCompanyLogo(base64Url2);
                      setSaveLogo(e.target.files);
                    }}
                  />

                  {viewCompanyLogo || shopData?.logo ? (
                    <div className="imageStyles" style={{ overflow: "hidden" }}>
                      <label
                        htmlFor="companyLogo"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={viewCompanyLogo || shopData?.logo}
                          alt="Image"
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                            objectFit: "cover",
                          }}
                        />
                      </label>
                    </div>
                  ) : (
                    <div
                      className="h-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#0000008C",
                        borderRadius: "50%",
                      }}
                    >
                      <label
                        htmlFor="companyLogo"
                        className="h-100 w-100 d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <FaCamera fontSize="43" color="#CBD0DD" />
                      </label>
                    </div>
                  )}
                </div>

                <span style={{ color: "#191D23" }}>Company Logo</span>
              </div>
              <div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    placeholder="Company Name"
                    value={shopData?.companyName || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    type="text"
                    id="description"
                    placeholder="Description"
                    value={shopData?.description || ""}
                    disabled={isViewMode} // Disable in view mode
                    rows={4}
                    cols={40}
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="day&time">
                    Opening Days & Time
                  </label>
                  <button
                    disabled={isViewMode}
                    type="button"
                    onClick={() => setModalIsOpen(true)}
                  >
                    Select Opening Days & Time
                  </button>
                  {/* Modal for selecting opening hours */}
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    className="modal-main-div col-lg-4 col-12 offset-lg-4 mt-1"
                  >
                    <div>
                      <div className="modal-header">
                        <h3>Shop Timing</h3>
                        <FaTimes
                          onClick={() => setModalIsOpen(false)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      <div>
                        <span>Select Shop Opening Days & Time</span>
                      </div>

                      <div className="modal-body">
                        <label>
                          <input
                            type="checkbox"
                            checked={allDay}
                            onChange={toggleAllDay}
                          />
                          All Day
                        </label>
                        <label style={{ float: "right" }}>
                          24 Hour format
                          <input
                            type="checkbox"
                            checked={!is12HourFormat} // Inverse logic, checked for 24-hour format
                            onChange={() => setIs12HourFormat(!is12HourFormat)} // Toggle state
                          />
                        </label>

                        {shopOpeningHours.map((day) => (
                          <div key={day.id} className="day-row mt-2 mb-2">
                            <label>
                              <input
                                type="checkbox"
                                checked={day.isOpen}
                                onChange={() => toggleDaySelection(day.id)}
                              />
                              {day.day}
                            </label>
                            <div className="time-inputs">
                              <input
                                type="time"
                                value={day.openingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day.id,
                                    "openingTime",
                                    e.target.value
                                  )
                                }
                                disabled={!day.isOpen}
                              />
                              <input
                                type="time"
                                value={day.closingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day.id,
                                    "closingTime",
                                    e.target.value
                                  )
                                }
                                disabled={!day.isOpen}
                              />
                            </div>
                          </div>
                        ))}

                        <button onClick={handleSave}>Save</button>
                      </div>
                    </div>
                  </Modal>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="category">
                    Shop Category
                  </label>
                  <select
                    name="category"
                    value={shopData?.category || ""}
                    disabled={isViewMode} // Disable in view mode
                    required
                    onChange={(e) =>
                      setAddShop({
                        ...addShop,
                        shopCategory: e.target.value,
                      })
                    }
                  >
                    {!isViewMode && (
                      <option value="" hidden>
                        Shop Category
                      </option>
                    )}
                    {categories.map((category, idx) => (
                      <option key={idx} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-one">
                  <label className="mb-1" htmlFor="certificate">
                    Halal Certificate
                  </label>
                  <input
                    type="file"
                    id="certificate"
                    disabled={isViewMode}
                    onChange={(e) => {
                      setSaveCertificate(e.target.files);
                    }}
                  />
                </div>

                <div className="input-one">
                  <label className="mb-1" htmlFor="companyPictures">
                    Company Pictures
                  </label>
                  <input
                    type="file"
                    id="companyPictures"
                    maxLength="8"
                    disabled={isViewMode}
                    multiple
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files) {
                        const base64Urls = Array.from(files).map((file) =>
                          URL.createObjectURL(file)
                        );
                        setViewCompanyPics(base64Urls); // Display selected images
                        setSaveCompanyPics(files); // Save selected files for upload
                      }
                    }}
                  />
                  <div className="selected-images d-flex gap-2 flex-wrap mt-2">
                    {Array.isArray(viewCompanyPics) &&
                    viewCompanyPics.length > 0 ? (
                      viewCompanyPics.map((url, idx) => (
                        <img
                          key={idx}
                          src={url}
                          alt={`image-${idx}`}
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                          }}
                        />
                      ))
                    ) : Array.isArray(shopData?.shopPicturesUrls) &&
                      shopData.shopPicturesUrls.length > 0 ? (
                      shopData.shopPicturesUrls.map((url, idx) => (
                        <img
                          key={idx}
                          src={url}
                          alt={`image-${idx}`}
                          width="100%"
                          height="100%"
                          style={{
                            width: "140px",
                            height: "140px",
                          }}
                        />
                      ))
                    ) : (
                      <p className="text-center pt-4">No images available</p> // Optional fallback message
                    )}
                  </div>
                </div>
              </div>

              <ButtonFill
                disabled={isViewMode}
                className={viewCompanyPics ? "mt-1" : "mt-5"}
                title={isEditMode ? "Update Shop" : "Save Shop"}
              />

              {loading && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // semi-transparent background
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000, // ensures it is above other content
                    backdropFilter: "blur(1px)", // blurs the background
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="40"
                    width="40"
                    color="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    strokeColor="#18432d"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </form>
      )}
    </div>
  );
};

export default AddShopForm;
