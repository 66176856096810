import React, { useState } from "react";
import "./LoginForm.css";
import logoImg from "../../assets/imgs/logo 1.svg";
import { Link } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import {
  auth,
  db,
  doc,
  getDoc,
  signInWithEmailAndPassword,
} from "../../firebase/firebaseConfig";
import Swal from "sweetalert2";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const loginUser = async (e) => {
    e.preventDefault();

    try {
      const response = await signInWithEmailAndPassword(
        auth,
        user.email,
        user.password
      );
      const userId = response.user.uid;
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Welcome to HalalCheck",
          timer: 1500,
        });
      }
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        localStorage.setItem(
          "userName",
          JSON.stringify({
            name: userData.name,
            image: userData.profileImageUrl,
          })
        );
      } else {
      }
    } catch (error) {
      setErrorMessage(error.code);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="login-container">
      <div className="login-child">
        <div className="mb-5">
          <div className="logo">
            <img src={logoImg} alt="HalalCheck Logo" />
          </div>
          <div className="mt-3">
            <h1>HalalCheck</h1>
          </div>
        </div>
        <form className="login-form" onSubmit={loginUser}>
          <div className="input-login">
            <label className="mb-2" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="johndoe@email.com"
              onChange={(e) => {
                setUser({
                  ...user,
                  email: e.target.value,
                });
              }}
              required
            />
          </div>
          <div className="input-login">
            <div className="d-flex justify-content-between align-items-center">
              <label className="mb-2" htmlFor="password">
                Password
              </label>
              <Link to="/forgot-password" className="forgot">
                Forgot Password?
              </Link>
            </div>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="************"
                onChange={(e) => {
                  setUser({
                    ...user,
                    password: e.target.value,
                  });
                }}
                required
              />
              <span
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </span>
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger text-center mb-2">{errorMessage}</div>
          )}
          <div className="options">
            <input type="checkbox" id="keep-signed-in" />
            <label htmlFor="keep-signed-in">Keep me signed in</label>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
