import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout/MainLayout";
import "./Sales.css";
import Table from "../Table/Table";
import ButtonFill from "../ButtonFill/ButtonFill";
import * as XLSX from "xlsx";
import { collection, db, getDocs } from "../../firebase/firebaseConfig";

const Sales = () => {
  const [allSales, setAllSales] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSales, setFilteredSales] = useState([]);

  useEffect(() => {
    try {
      setLoader(true);
      const getSalesData = async () => {
        const getAllSales = await getDocs(collection(db, "sales"));
        const salesData = [];

        getAllSales.forEach((doc) => {
          salesData.push({ id: doc.id, ...doc.data() });
        });
        setAllSales(salesData);
        setLoader(false);
      };
      getSalesData();
    } catch (e) {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = allSales.filter(
        (sale) =>
          sale.platform.toLowerCase().includes(searchTerm.toLowerCase()) ||
          sale.id.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSales(filtered);
    } else {
      setFilteredSales(allSales);
    }
  }, [searchTerm, allSales]);

  const tableHeaders = ["Date", "Transaction Type", "Receipt", "Amount"];

  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = allSales.map((row) => [
      row.subEndDate,
      row.platform,
      row.id,
      row.price,
    ]);

    worksheetData.unshift(tableHeaders);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales");

    XLSX.writeFile(workbook, "SalesReport.xlsx");
  };

  return (
    <MainLayout setSearchTerm={setSearchTerm}>
      <section className="content p-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="all-subscribe">Sales</span>
          <div>
            <ButtonFill title="Download Sales Report" onclick={downloadExcel} />
          </div>
        </div>
        <Table
          headers={tableHeaders}
          loader={loader}
          tableData={
            filteredSales.length > 0 ? (
              filteredSales
            ) : (
              <div className="no-data-found">No Data Found</div>
            )
          }
          salesTable={true}
        />
      </section>
    </MainLayout>
  );
};

export default Sales;
