import React from "react";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";

const MainLayout = ({ children, setSearchTerm }) => {
  return (
    <div className="container-fluid p-0">
      <div className="row g-0" style={{ height: "100vh" }}>
        <div
          className="col-md-3 col-xl-2 sidebar p-0"
          style={{ height: "100vh" }}
        >
          <SideBar />
        </div>
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <SideBar closeButton={true} />
        </div>
        <div
          className="col-md-9 col-xl-10 overflow-auto"
          style={{ height: "100vh" }}
        >
          <NavBar title="Hello Admin" setSearchTerm={setSearchTerm} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
