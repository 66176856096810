import React from 'react'

const ButtonUnfill = ({onclick, unfillBtnWidth,title}) => {
  return (
    <div style={{width: unfillBtnWidth}}>
        <button className='unfillBtn w-100' type='submit' onClick={onclick}>{title}</button>
    </div>
  )
}

export default ButtonUnfill