import React from "react";
import "./ForgotPassword.css";
import imgLogo from "../../assets/imgs/logo 1.svg";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  return (
    <div className="forgot-password-container">
      <div className="forgot-password-child">
        <div className="mb-5">
          <div className="logo">
            <img src={imgLogo} alt="HalalCheck Logo" />
          </div>
          <div className="mt-3">
            <h1>HalalCheck</h1>
          </div>
        </div>
        <h2>Forgot Password?</h2>
        <p>Enter your email address to get the password reset link</p>
        <form className="forgot-password-form mt-4">
          <div className="input-login">
            <label className="mb-2" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="johndoe@email.com"
              required
            />
          </div>
          <button type="submit">Password Reset</button>
        </form>
        <Link to="/" className="back-to-login">
          Back To Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
