import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,

  plugins: {
    legend: {
      position: "top",
      labels: {},
    },
    title: {},
  },

  scales: {
    x: {
      grid: {
        display: true,
      },
    },
    y: {
      beginAtZero: true,

      ticks: {
        stepSize: 60000,
        callback: function (value) {
          if (value === 0) {
            return "$0K";
          }
          if (value >= 1000) {
            return "$" + value / 1000 + "K";
          }
          return value;
        },
      },
      grid: {
        display: true,
      },
    },
  },
};

const labels = [
  "17 Sun",
  "18 Mon",
  "19 Tue",
  "20 Wed",
  "21 Thu",
  "22 Fri",
  "23 Sat",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Last week",
      data: [90000, 130000, 100000, 200000, 140000, 70000, 120000],
      backgroundColor: "#18432D",
      borderRadius: 7,
    },
    {
      label: "This week",
      data: [150000, 110000, 180000, 80000, 100000, 200000, 150000],
      backgroundColor: "#41B478",
      borderRadius: 7,
    },
  ],
};

const BarChart = () => {
  return (
    <div>
      <Bar options={options} data={data} height="100%" />
    </div>
  );
};

export default BarChart;
